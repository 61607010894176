<template>
  <!-- 发票详情 -->
  <div class="InvoiceDetail">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">">
      <a-breadcrumb-item
        ><router-link to="/personalCenter/MyOrder"
          >我的订单</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">发票信息</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content_box">
      <!-- 发票信息 -->
      <div class="box box1">
        <div class="box_title">发票信息</div>
        <div class="box_con">
          <div class="con_img">
            <!-- <img src="@/assets/image/noMessage.png" alt="发票"> -->
            <!-- <img src="https://cos.hxclass.cn/test/invoice/20220921/ef0e3875c9b244e482f02f4dc7a66d1e.pdf" alt="发票"> -->
            <!-- <iframe id="my-iframe" @load="initializeIframe" :src="invoiceDetail.pdf + '#toolbar=0'" width="100%" height="100%" frameborder="0"></iframe> -->
            <pdf 
              @click.native="reviewInvoice"
              ref="pdf"
              :src="invoiceDetail.pdf">
            </pdf>
          </div>
          <div class="con_main">
            <div class="main_txt">
              <div class="txt_wrap">
                <div class="wrap_item">
                  <span>抬头类型：</span>
                  <span>{{getInvoiceType(invoiceDetail.type)}}</span>
                </div>
                <div class="wrap_item">
                  <span>发票抬头：</span>
                  <span>{{invoiceDetail.title}}</span>
                </div>
                <div class="wrap_item">
                  <span>电子邮箱：</span>
                  <span>{{invoiceDetail.email}}</span>
                </div>
                <div class="wrap_item" v-if="invoiceDetail.companyPhone">
                  <span>联系电话：</span>
                  <span>{{invoiceDetail.companyPhone}}</span>
                </div>
              </div>
              <div class="txt_wrap">
                <div class="wrap_item">
                  <span>申请时间：</span>
                  <span>{{invoiceDetail.applyTime}}</span>
                </div>
                <div class="wrap_item">
                  <span>发票内容：</span>
                  <span>{{invoiceDetail.invoiceContent}}</span>
                </div>
                <div class="wrap_item">
                  <span>开票金额：</span>
                  <span>¥{{(invoiceDetail.price*100/100).toFixed(2)}}</span>
                </div>
                <div class="wrap_item" v-if="invoiceDetail.isChange == 1 && false">
                  <span>换开记录：</span>
                  <span class="light" @click="isChangeModal = true">记录</span>
                </div>
              </div>
            </div>
            <div class="main_button">
              <!-- <div class="all-btn-blue" @click="isAddInvoiceModal=true">申请换开</div> -->
              <div class="all-btn-blue" v-if="$route.query.payMethod != 3" @click="onReplaceVoice()">申请换开</div>
              <div class="all-btn-blue" @click="isInvoiceEmailModal=true">发送邮箱</div>
              <div class="all-btn-small" @click="downInvoice(invoiceDetail.pdf)">下载发票</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="box box2">
        <div class="box_title">商品信息</div>
        <div class="productList">
          <div class="topTitle">
            <p class="productMess">商品信息</p>
            <p class="num">数量</p>
            <p class="price">价格</p>
            <p class="coupon">下单时间</p>
          </div>
          <div class="productItem" v-for="(item,index) in productList" :key="index">
            <div class="productMess">
              <img v-if="!invoiceDetail.isFreight" :src="item.productPicture" alt="">
              <div class="product">
                <p class="name" @click="goDetail(item)">{{item.productName}}</p>
                <p class="type" v-if="item.categoryName">{{item.categoryName}} | {{item.classHour}}课时</p>
              </div>
            </div>
            <div class="num" v-if="!invoiceDetail.isFreight">x{{item.productCount}}</div>
            <div class="num" v-else>x1</div>
            <div class="price">{{item.amount || '-'}}</div>
            <div class="coupon">{{invoiceDetail.createOrderTime}}</div>
          </div>
        </div>
        <div class="box_price">
          <div class="price_num">共{{productList.length}}件商品</div>
          <div class="price_info">
            <span class="info_txt">实付款：</span>
            <span class="info_main">¥{{(invoiceDetail.price*100/100).toFixed(2)}}</span>
          </div>
        </div>
      </div>
      <!-- 换开记录 -->
      <div class="box3">
        <a-modal
          :width="448"
          :visible="isChangeModal"
          :closable="false"
        >
          <div class="modal_box">
            <div class="box_title">换开记录</div>
            <div class="box_con">
              <div class="con_item">
                <span class="item_title">换开时间：</span>
                <span class="item_txt">{{invoiceChangeDetail.applyTime}}</span>
              </div>
              <div class="con_item">
                <span class="item_title">抬头类型：</span>
                <span class="item_txt">{{getInvoiceType(invoiceChangeDetail.type)}}</span>
              </div>
              <div class="con_item">
                <span class="item_title">发票抬头：</span>
                <span class="item_txt">{{invoiceChangeDetail.title}}</span>
              </div>
              <div class="con_item">
                <span class="item_title">电子邮箱：</span>
                <span class="item_txt">{{invoiceChangeDetail.email}}</span>
              </div>
              <div class="con_item">
                <span class="item_title">发票内容：</span>
                <span class="item_txt">{{invoiceChangeDetail.invoiceContent}}</span>
              </div>
            </div>
            <div class="box_button">
              <div class="all-btn-small" @click="isChangeModal = false">确认</div>
            </div>
          </div>
          <template #footer></template>
        </a-modal>
      </div>
      <!-- 换开发票 -->
      <invoiceModal 
        :invoiceType="2"
        :isAddInvoiceModal="isAddInvoiceModal"
        :invoiceRecordId="invoiceRecordId"
        @onCancelAdd="onCancelAdd"
        @onConfirmAdd="onConfirmAdd"></invoiceModal>
      <!-- 发送邮箱 -->
      <invoiceEmail 
        :invoiceRecordId="invoiceRecordId"
        :isInvoiceEmailModal="isInvoiceEmailModal" 
        @onCancelEmail="onCancelEmail"
        @onConfirmEmail="onConfirmEmail"></invoiceEmail>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import invoiceModal from '@/components/model/invoiceModal.vue' // 添加发票抬头
import invoiceEmail from '@/components/model/invoiceEmail.vue' // 发送邮箱
import { openFile } from '../../unit/fun';
export default {
  // 可用组件的哈希表
  components: {
    invoiceModal, // 添加发票抬头
    invoiceEmail, // 发送邮箱
    pdf
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      invoiceRecordId: null, //发票id
      invoiceChangeRecordId: null, //换开记录id
      isInvoiceEmailModal: false, //发送邮箱弹框
      isAddInvoiceModal: false, //发票抬头弹框
      // 换开记录弹框
      isChangeModal: false,
      // 发票详情
      invoiceDetail: {}, 
      // 换开详情
      invoiceChangeDetail: {},
      // 商品列表
      productList: [
        {
          actualPrice: null,
          address: null,
          answerNumber: null,
          appleIapId: null,
          cityTxt: null,
          className: null,
          code: "AK0044",
          count: 1,
          couponPrice: 1580,
          courseCategory: "上岗课程",
          courseHour: "视频数量：209个",
          days: null,
          delaysNum: null,
          districtTxt: null,
          endTime: null,
          examNumber: null,
          freightPrice: null,
          listPhoto: "https://cos.hxclass.cn/test/management/20220714/a33d634bc1234853a55b1b0cdf724c17.jpg",
          liveState: null,
          liveTime: null,
          makeNum: null,
          name: "孤独症康复教育上岗培训课程",
          noGift: 0,
          noPay: 0,
          openTimeId: null,
          original: 0,
          originalPrice: 1580,
          productId: 1,
          provinceTxt: null,
          sparePrice: null,
          startTime: null,
          totalPrice: 1580,
          orderTime: '2022-01-22 19:22:22',
          type: 1,
        },
        {
          actualPrice: null,
          address: null,
          answerNumber: null,
          appleIapId: null,
          cityTxt: null,
          className: null,
          code: "AT0009",
          count: 1,
          couponPrice: 35,
          courseCategory: null,
          courseHour: null,
          days: null,
          delaysNum: null,
          districtTxt: null,
          endTime: null,
          examNumber: null,
          freightPrice: null,
          listPhoto: "https://cos.hxclass.cn/prod/management/20220619/bd3c523aedb644729239bf186ebf455c.png",
          liveState: null,
          liveTime: null,
          makeNum: null,
          name: "孤独症康复教育人员上岗培训教材（第二版）",
          noGift: 0,
          noPay: null,
          openTimeId: null,
          original: 0,
          originalPrice: 35,
          productId: 6,
          provinceTxt: null,
          sparePrice: null,
          startTime: null,
          totalPrice: 35,
          orderTime: '2022-01-22 19:22:22',
          type: 2,
        },
        {
          actualPrice: null,
          address: null,
          answerNumber: null,
          appleIapId: null,
          cityTxt: null,
          className: null,
          code: "AT0012",
          count: 1,
          couponPrice: 3500,
          courseCategory: null,
          courseHour: null,
          days: null,
          delaysNum: null,
          districtTxt: null,
          endTime: null,
          examNumber: null,
          freightPrice: null,
          listPhoto: "https://cos.hxclass.cn/prod/management/20220619/6b4a104beefe4973877193d3af1d9bc2.jpg",
          liveState: null,
          liveTime: null,
          makeNum: null,
          name: "语言行为评估配套图片",
          noGift: 0,
          noPay: null,
          openTimeId: null,
          original: 0,
          originalPrice: 3500,
          productId: 11,
          provinceTxt: null,
          sparePrice: null,
          startTime: null,
          totalPrice: 3500,
          orderTime: '2022-01-22 19:22:22',
          type: 2
        }
      ],
      // 课程列表
      coursePcDetailVOList: [],
    }
  },
  // 事件处理器
  methods: {
    // ifream初始化完成事件
    initializeIframe() {
      const iframe = document.getElementById('my-iframe');
      iframe.style.pointerEvents = 'none'; // 确保iframe内容不会阻止鼠标事件

      // 添加监听器到父元素
      iframe.parentElement.addEventListener('click', this.handleIframeClick);
    },
    handleIframeClick(event) {
      const iframe = document.getElementById('my-iframe');
      const iframeRect = iframe.getBoundingClientRect();

      // 检查点击是否发生在iframe内
      if (event.clientX >= iframeRect.left &&
          event.clientX <= iframeRect.right &&
          event.clientY >= iframeRect.top &&
          event.clientY <= iframeRect.bottom) {
        // 点击事件发生在iframe内部
        console.log('Click inside iframe');
        window.open(this.invoiceDetail.pdf);
      }
    },
    // 查看发票
    reviewInvoice() {
      window.open(this.invoiceDetail.pdf);
    },
    // 发票详情
    getInvoiceDetail() {
      this.$ajax({
        url: "/hxclass-pc/invoice/" + this.invoiceRecordId,
        // url: "/hxclass-pc/invoice/16640",
        method: 'get',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 发票详情
          this.invoiceDetail = res.data;
          // 商品列表
          this.productList = this.invoiceDetail.productList;
          this.courseProductVOList = this.invoiceDetail.courseProductVOList;
          // 上课课程、课时
          for ( var i = 0 ; i < this.productList.length ; i++ ) {
            for ( var j = 0 ; j < this.courseProductVOList.length ; j++ ) {
              if (this.productList[i].productId == this.courseProductVOList[j].productId) {
                this.productList[i].categoryName = this.courseProductVOList[j].categoryName || ''; //课程类型
                this.productList[i].classHour = this.courseProductVOList[j].classHour || ''; //课时
                break;
              }
            }
          }
          console.log(this.productList)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 换开记录
    onChangeDetail() {
      // 打开换开记录
      this.isChangeModal = true
      // 换开记录id
      // this.invoiceChangeRecordId = this.invoiceDetail.changeId
      this.invoiceChangeRecordId = 16559
      this.$ajax({
        url: "/hxclass-pc/invoice/" + this.invoiceChangeRecordId,
        method: 'get',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 发票详情
          this.invoiceChangeDetail = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取发票类型
    getInvoiceType(con) {
      if (con == 1) {
        return '企业'
      } else if (con == 3) {
        return '个人'
      }
    },
    // 商品详情
    goDetail(item) {
      console.log(item)
    },
    // 关闭换开记录
    handleCancel() {
      this.isChangeModal = false;
    },
    // 取消发送邮箱
    onCancelEmail() {
      this.isInvoiceEmailModal = false
    },
    // 确定发送邮箱
    onConfirmEmail() {
      this.isInvoiceEmailModal = false
    },
    // 添加发票弹框 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false
    },
    // 添加发票弹框 - 确定
    onConfirmAdd() {
      this.isAddInvoiceModal = false
    },
    onConfirm() {
      this.isPublicTipsShow = false;
    },
    // 换开发票
    onReplaceVoice(){
      this.$router.replace({
        path:
          "/invoice/replaceVoice?invoiceRecordId=" +
          this.$AES.encode_data(String(this.invoiceRecordId)),
      });
    },
    // 下载发票
    downInvoice(url) {
      let fileName = this.getDay()
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/
      if (!reg.test(url)) {
        throw new Error('传入参数不合法,不是标准的文件链接')
      } else {
        let xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.setRequestHeader('Content-Type', `application/pdf`)
        xhr.responseType = 'blob'
        let that =this
        xhr.onload = function() {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response
            that.downloadExportFile(blob, fileName)
          }
        }
        xhr.send()
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement('a')
      let href = blob
      if (typeof blob == 'string') {
        downloadElement.target = '_blank'
      } else {
        href = window.URL.createObjectURL(blob) //创建下载的链接
      }
      downloadElement.href = href
      downloadElement.download = tagFileName
      //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      if (typeof blob != 'string') {
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
      year = time.getFullYear(),
      month = time.getMonth() + 1,
      day = time.getDate(),
      timeStem = time.getTime()
      return `${year}/${month}/${day}/${timeStem}.pdf`
    },
    showBig() {
      console.log("查看大图")
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 发票id
    this.invoiceRecordId = this.$AES.decode_data(this.$route.query.invoiceRecordId);
    // console.log(this.invoiceRecordId)
    // 发票详情
    this.getInvoiceDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { 
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .InvoiceDetail{
    width: 1400px;
    margin: 0 auto;
    padding-top: 92px;
    .content_box {
      margin-top: 22px;
      margin-bottom: 40px;
      padding: 40px 40px 60px 40px;
      background: #fff;
      border-radius: 5px;
      .box {
        .box_title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 500;
          color: #15B7DD;
          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 19px;
            margin-right: 8px;
            background: #15B7DD;
          }
        }
      }
      // 发票信息
      .box1 {
        .box_con {
          display: flex;
          margin-top: 24px;
          .con_img {
            width: 502px;
            height: 291px;
            position: relative;
            img {
              width: 100%;
            }
            .clickDiv {
              position: absolute;
              top: 0;
              left: 0;
              width: 502px;
              height: 291px;
              background-color: #FF0000;
            }
          }
          .con_main {
            margin-left: 64px;
            padding-top: 10px;
            .main_txt {
              display: flex;
              .txt_wrap {
                &:nth-child(2) {
                  margin-left: 100px;
                }
                .wrap_item {
                  margin-bottom: 20px;
                  span:nth-child(1) {
                    font-size: 16px;
                    font-family: PingFang HK-Regular, PingFang HK;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                  }
                  span:nth-child(2) {
                    font-size: 16px;
                    font-family: PingFang HK-Medium, PingFang HK;
                    font-weight: 500;
                    color: #333333;
                    line-height: 24px;
                    &.light {
                      color: #15B7DD;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .main_button {
              display: flex;
              margin-top: 35px;
              .all-btn-blue {
                font-size: 16px;
                margin-right: 54px;
              }
              .all-btn-small {
                font-size: 16px;
              }
            }
          }
        }
      }
      // 商品信息
      .box2 {
        margin-top: 55px;
        .productList{
          margin-top: 24px;
          // margin-left: 12px;
          border: 1px solid #EBEBEB;
          border-top: 0;
          .productItem{
            margin: 0 37px;
            border-bottom: 1px solid #EBEBEB;
            .productMess{
              padding: 28px 0;
              display: flex;
              img{
                width: 100px;
                height: 87px;
              }
              .product{
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;
                padding: 4px 18px;
                font-size: 14px;
                font-family: PingFang HK-Regular, PingFang HK;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
                text-align: left;
                .name {
                  // cursor: pointer;
                }
                .type{
                  color: #1E97B4;
                  margin-top: 16px;
                }
              }
            }
            .price, .coupon {
              font-size: 16px;
            }
            .num {
              font-size: 14px;
            }
          }
          .productItem:last-child{
            border-bottom: 0;
          }
          .topTitle{
            background: #F3F3F3;
            height: 54px;
            font-size: 16px;
            font-family: PingFang HK-Medium, PingFang HK;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            padding: 0 37px;
          }
          .topTitle,.productItem{
            display: flex;
            text-align: center;
            align-items: center;
            .productMess{
              flex: 1;
            }
            .price{
              width: 200px;
            }
            .num{
              width: 200px;
            }
            .coupon{
              width: 200px;
            }
            .allPrice{
              width: 200px;
            }
          }
        }
        .box_price {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 24px;
          .price_num {
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .price_info {
            display: flex;
            align-items: center;
            line-height: 24px;
            margin-left: 10px;
            .info_txt {
              font-size: 16px;
              font-family: PingFang HK-Regular, PingFang HK;
              font-weight: 400;
              color: #333333;
            }
            .info_main {
              font-size: 24px;
              font-family: PingFang HK-Medium, PingFang HK;
              font-weight: 500;
              color: #FF0000;
            }
          }
        }
      }
    }
  }
  // 换开记录
  /deep/.ant-modal-footer {
    display: none;
  }
  .modal_box {
    padding: 0 62px;
    .box_title {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      margin-top: 15px;
    }
    .box_con {
      margin-top: 32px;
      .con_item {
        margin-bottom: 24px;
        .item_title {
          font-size: 16px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .item_txt {
          font-size: 16px;
          font-family: PingFang HK-Medium, PingFang HK;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
          margin-left: 16px;
        }
      }
    }
    .box_button {
      margin-top: 66px;
      .all-btn-small {
        width: 113px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        margin: 0 auto;
      }
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .InvoiceDetail {
      width: 1200px;
      .content_box {
        .box .box_title {
          font-size: 22px;
        }
        .box1 .box_con {
          .con_img {
            width: 434px;
          }
          .con_main {
            margin-left: 70px;
            .main_button .all-btn-blue {
              margin-right: 44px;
            }
          }
        }
      }
    }
  }
</style>
